import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import type { MeetingType } from '@/types';

interface State {
  teamId: string;
  meetingId: string;
  selectedMeetingType: MeetingType | null;
  isInternalMeeting: boolean;
}

interface Action {
  setTeamId: (teamId: string) => void;
  setMeetingId: (teamId: string) => void;
  setSelectedMeetingType: (meetingType: MeetingType) => void;
  setIsInternalMeeting: (isInternalMeeting: boolean) => void;
  reset: () => void;
}

const initialState: State = {
  teamId: '',
  meetingId: '',
  selectedMeetingType: null,
  isInternalMeeting: false,
};

export const useMeetingStore = create<State & Action>()(
  persist(
    (set) => ({
      ...initialState,

      setTeamId: (teamId) => {
        set({ teamId });
      },
      setMeetingId: (meetingId) => {
        set({ meetingId });
      },
      setSelectedMeetingType: (selectedMeetingType) => {
        set({ selectedMeetingType });
      },
      setIsInternalMeeting: (isInternalMeeting) => {
        set({ isInternalMeeting });
      },
      reset: () => {
        set(() => ({
          ...initialState,
        }));
      },
    }),
    {
      name: 'meeting-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import { type QueryConfig } from '@/lib/reactQuery';
import type {
  MeetingDetails,
  Meeting,
  MeetingNote,
  Scorecard,
  Script,
  Response,
  SpeechData,
} from '@/types';

interface GetMeetingDetailsParams {
  teamId: string;
  meetingId: string;
}

interface GetMeetingDetails {
  meeting: Meeting;
  meetingScorecard: Scorecard | null;
  meetingScript: Script | null;
  meetingNotes: MeetingNote[] | null;
  speechData: SpeechData[] | null;
}

export const getMeetingDetails = async ({
  teamId,
  meetingId,
}: GetMeetingDetailsParams): Promise<Response<GetMeetingDetails>> => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'x-request-id': uuidv4(),
      'Content-Type': 'application/json',
      'X-Quil-Token': `${localStorage.getItem('X-Quil-Token') ?? ''}`,
    },
  };

  const response = await fetch(
    `${Server.middlewareUrl}/v1/teams/${teamId}/meetings/${meetingId}/details`,
    requestOptions
  );

  await _handleErrors(response);
  return await response.json();
};

type QueryFnType = typeof getMeetingDetails;

interface UseMeetingDetailsOptions {
  teamId?: string;
  meetingId?: string;
  config?: QueryConfig<QueryFnType>;
}

export const useMeetingDetails = ({
  teamId,
  meetingId,
  config,
}: UseMeetingDetailsOptions) => {
  return useQuery<MeetingDetails, unknown, MeetingDetails, any>({
    ...config,
    queryKey: ['meeting-details', teamId, meetingId],
    queryFn: () =>
      teamId && meetingId && getMeetingDetails({ teamId, meetingId }),
    enabled: !!teamId && !!meetingId,
    select: (response: Response<GetMeetingDetails>) => response.data,
  });
};

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  Typography,
  Skeleton,
  CircularProgress,
} from '@mui/material';

import {
  INTERNAL_MEETING,
  INTERNAL_MEETING_TYPE,
  SCORECARD_COLORS,
} from '@/utils/constants';
import {
  MeetingTypeCard,
  MeetingTypeCardSkeleton,
} from '@/features/scorecards/components/MeetingTypeCard';
import { useMeetingStore } from '@/stores/meeting';
import type { TeamAggregate, MeetingType } from '@/types';
import { useAccountContext } from '@/providers';
import { useCreateMeeting } from '@/features/meeting/api/createMeeting';

interface Props {
  team: TeamAggregate;
}

export const TeamListSkeleton = () => {
  return (
    <Box sx={{ mb: 4 }}>
      <Skeleton variant="text" width={100} height={40} animation="wave" />
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={3} maxWidth={1200}>
        {[...Array(9)].map((_, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <MeetingTypeCardSkeleton />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const TeamList = ({ team }: Props) => {
  const {
    setTeamId,
    setMeetingId,
    setSelectedMeetingType,
    setIsInternalMeeting,
  } = useMeetingStore();
  const { zoomMeetingInfo } = useAccountContext();
  const navigate = useNavigate();

  const { mutate: createMeeting, isLoading } = useCreateMeeting({});

  const handleMeetingTypeSelect = useCallback(
    (meetingType: MeetingType) => {
      setTeamId(team.$id);
      setSelectedMeetingType(meetingType);

      const defaultMeetingTitle =
        zoomMeetingInfo?.meetingTopic ??
        'Zoom Meeting - ' +
          new Date(Date.now()).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          });

      if (meetingType.name !== INTERNAL_MEETING) {
        setIsInternalMeeting(false);
      } else {
        setIsInternalMeeting(true);
      }

      createMeeting(
        {
          teamId: team.$id,
          meetingTypeId: meetingType.$id,
          description: defaultMeetingTitle,
        },
        {
          onSuccess: (response) => {
            setMeetingId(response.data.meeting.$id);
            navigate('/app/meeting/redirect', { replace: true });
          },
        }
      );
    },
    [
      setTeamId,
      setMeetingId,
      zoomMeetingInfo,
      setSelectedMeetingType,
      setIsInternalMeeting,
      navigate,
      createMeeting,
      team,
    ]
  );

  return (
    <Box key={team.$id} sx={{ mb: 4 }}>
      <Typography variant="h4">{team.name}</Typography>
      <Divider sx={{ mb: 2 }} />

      {isLoading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3} maxWidth={1200}>
          {team.meetingTypes.map((meetingType: MeetingType, index) => (
            <Grid item key={meetingType.$id} xs={12} sm={6} md={4}>
              <MeetingTypeCard
                meetingType={meetingType}
                handleSelect={handleMeetingTypeSelect}
                bgColor={SCORECARD_COLORS[~~(index % 3)]}
              />
            </Grid>
          ))}

          {/* Notes only meeting */}
          <Grid item xs={12} sm={6} md={4}>
            <MeetingTypeCard
              meetingType={INTERNAL_MEETING_TYPE}
              handleSelect={handleMeetingTypeSelect}
              bgColor={SCORECARD_COLORS[4]}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { useDebounce } from '@/hooks/useDebounce';

import { useMeetingDetails } from '../api/getMeetingDetails';
import { useIntegrationDeals } from '../api/getIntegrationDeals';
import { useGetDealById } from '../api/getDealById';
import { useLinkIntegrationDeal } from '../api/linkIntegrationDeal';
import { formatContactList } from '@/utils/format';

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  teamId: string;
  meetingId: string;
}

export const SearchDeals = ({ teamId, meetingId }: Props) => {
  const [value, setValue] = useState<SelectOption | null>(null);
  const [inputValue, setInputValue] = useState('');
  const hint = useDebounce(inputValue, 500);

  const { data: meetingDetails } = useMeetingDetails({ teamId, meetingId });

  const { data: deal } = useGetDealById({
    teamId,
    dealId: meetingDetails?.meeting.externalContactId,
  });

  const { data, isLoading: isDealsLoading } = useIntegrationDeals({
    teamId,
    limit: 10,
    hint,
  });

  const deals = formatContactList(data ?? []);

  const { mutate: linkIntegrationDeal, isLoading: isLinkDealLoading } =
    useLinkIntegrationDeal({});

  // Update the value on page load
  useEffect(() => {
    if (deal) {
      setValue({
        label: deal.name,
        value: deal.id,
      });
    }
  }, [deal]);

  return (
    <>
      <Autocomplete
        id="deals-autocomplete"
        data-testid="autocomplete"
        disablePortal
        disabled={isLinkDealLoading}
        options={deals.map((deal) => deal)}
        value={value}
        onChange={(_, item) => {
          setValue(item);
          if (item !== null) {
            void linkIntegrationDeal({
              teamId,
              meetingId,
              dealId: item.value,
            });
          }
        }}
        loading={isDealsLoading}
        inputValue={inputValue}
        onInputChange={(_, val) => {
          setInputValue(val);
        }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Deals" />}
        fullWidth
      />
    </>
  );
};

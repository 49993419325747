import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import { type MutationConfig } from '@/lib/reactQuery';

interface UpdateMeetingHeartbeatParams {
  teamId: string;
  meetingId: string;
}

export const updateMeetingHeartbeat = async ({
  teamId,
  meetingId,
}: UpdateMeetingHeartbeatParams) => {
  const request = {
    method: 'POST',
    headers: {
      'x-request-id': uuidv4(),
      'X-Quil-Token': localStorage.getItem('X-Quil-Token') ?? '',
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(
    `${Server.middlewareUrl}/v1/teams/${teamId}/meetings/${meetingId}/heartbeat`,
    request
  );

  await _handleErrors(response);
  return response;
};

interface UseUpdateMeetingHeartbeatOptions {
  teamId: string;
  meetingId: string;
  config?: MutationConfig<typeof updateMeetingHeartbeat>;
}

export const useUpdateMeetingHeartbeat = ({
  teamId,
  meetingId,
  config,
}: UseUpdateMeetingHeartbeatOptions) => {
  return useMutation({
    onError: async (error) => {
      console.error(error);
    },
    ...config,
    mutationFn: () => updateMeetingHeartbeat({ teamId, meetingId }),
  });
};

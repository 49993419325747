import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { CircularProgress as Spinner, Box } from '@mui/material';

// import { lazyImport } from '@/utils/lazyImport';
import { MainLayout } from '@/components/layout/MainLayout';
import { TeamsDashboard } from '@/features/teams/routes/TeamsDashboard';
import { Meeting } from '@/features/meeting/routes/Meeting';
import { MeetingRedirect } from '@/features/meeting/routes/MeetingRedirect';

// eslint-disable-next-line react-refresh/only-export-components
const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner />
          </Box>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      {
        path: '',
        element: <TeamsDashboard />,
      },
      {
        path: 'meeting/redirect',
        element: <MeetingRedirect />,
      },
      {
        path: 'meeting/ongoing',
        element: <Meeting />,
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />,
      },
    ],
  },
];

import { Box } from '@mui/material';

import { ReactComponent as LogoSvg } from '@/assets/icons/logo.svg';

interface Props {
  width?: number;
  height?: number;
  style?: any;
}

export const Logo = ({ width = 30, height = 30, style }: Props) => {
  return (
    <Box style={{ height, width, ...style }}>
      <LogoSvg />
    </Box>
  );
};

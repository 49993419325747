import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  CardMedia,
  Skeleton,
} from '@mui/material';

import { ReactComponent as PhoneCallSVG } from '@/assets/icons/phone-call.svg';
import type { MeetingType } from '@/types';

import { Wave } from './Wave';

interface Props {
  meetingType: MeetingType;
  handleSelect: (meetingType: MeetingType) => void;
  bgColor: string;
}

export const MeetingTypeCardSkeleton = () => {
  return (
    <Card sx={{ maxWidth: 350, minHeight: 135, margin: 'auto' }}>
      <CardContent>
        <Skeleton variant="text" width={150} height={40} animation="wave" />
      </CardContent>
    </Card>
  );
};

export const MeetingTypeCard = ({
  meetingType,
  handleSelect,
  bgColor,
}: Props) => {
  return (
    <Card sx={{ maxWidth: 350, margin: 'auto' }}>
      <CardActionArea
        onClick={() => handleSelect(meetingType)}
        data-cy="scorecard"
      >
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Typography component="h3" variant="h5">
              {meetingType.name}
            </Typography>
            <PhoneCallSVG />
          </Box>
        </CardContent>
        <CardMedia component={() => <Wave hexColor={bgColor} />} />
      </CardActionArea>
    </Card>
  );
};

import { Box, Container, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Logo } from '@/components/misc/Logo';

interface Props {
  title: string;
  children: React.ReactNode;
}

const StyledContainer = styled(Container)(() => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  minHeight: 'inherit',
  minWidth: 350,
  maxWidth: 525,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4),
  [theme.breakpoints.up(450)]: {
    padding: theme.spacing(6),
  },
  [theme.breakpoints.up('sm')]: {
    minHeight: 'initial',
    padding: theme.spacing(8),
    maxHeight: 850,
  },
}));

export const Layout = ({ title, children }: Props) => {
  return (
    <StyledContainer disableGutters>
      <StyledPaper elevation={3}>
        <Box display="flex" justifyContent="center" sx={{ mb: 5 }}>
          <Logo height={80} width={80} />
        </Box>
        <Typography component="h1" variant="h3" align="center" sx={{ mb: 1 }}>
          {title}
        </Typography>
        {children}
      </StyledPaper>
    </StyledContainer>
  );
};

import {
  FormProvider as Form,
  type SubmitHandler,
  type UseFormReturn,
} from 'react-hook-form';

interface Props {
  children: React.ReactNode;
  onSubmit: SubmitHandler<any>;
  methods: UseFormReturn<any, any>;
}

export const FormProvider = ({ children, onSubmit, methods }: Props) => {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
};

import { useMeetingHeartbeat } from '@/hooks/useMeetingHeartbeat';
import { useMeetingStore } from '@/stores/meeting';

export const useMeetingState = () => {
  const { selectedMeetingType, teamId, meetingId } = useMeetingStore();
  useMeetingHeartbeat();

  return {
    teamId,
    meetingId,
    selectedMeetingType,
  };
};

import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Typography, Link, useTheme } from '@mui/material';

import { Server } from '@/config';
import { useSnackbarStore } from '@/stores/snackbar';
import { useAccountContext } from '@/providers';
import { Logo } from '@/components/misc/Logo';

import { openExternalUrl } from '../api/openExternalUrl';
import { loginWithEmailAndPassword } from '../api/login';
import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { account, isLoading, isZoomSdkLoading } = useAccountContext();
  const { showSnackbar } = useSnackbarStore();
  const queryClient = useQueryClient();

  const handleLogin = useCallback(async (email: string, password: string) => {
    try {
      await loginWithEmailAndPassword(email, password);
      void queryClient.invalidateQueries({
        queryKey: ['account-details'],
      });
      navigate('/app', { replace: true });
    } catch (err) {
      err instanceof Error && showSnackbar(err.message, 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account && !isLoading) {
      navigate('/app', { replace: true });
    }
  }, [account, isLoading, navigate]);

  if (isLoading || isZoomSdkLoading || account) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%' }}
      >
        <Logo height={100} width={100} />
      </Box>
    );
  }

  return (
    <Layout title="Sign in to get started!">
      <LoginForm handleLogin={handleLogin} />
      <Typography
        variant="body2"
        align="center"
        color={theme.palette.text.secondary}
        sx={{ mt: 3 }}
      >
        <Link
          underline="always"
          target="_blank"
          rel="noreferrer"
          color="primary"
          onClick={() => {
            void openExternalUrl({
              url: `${Server.middlewareUrl}/v1/redirects/terms-and-conditions`,
            });
          }}
        >
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link
          underline="always"
          color="primary"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            void openExternalUrl({
              url: `${Server.middlewareUrl}/v1/redirects/privacy-policy`,
            });
          }}
        >
          Privacy Policy
        </Link>
      </Typography>
    </Layout>
  );
};

import { useMeetingRedirectState } from '../stateholder/MeetingRedirectState';
import { FormProvider } from '@/components/form';
import { Box, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const MeetingRedirect = () => {
  const { methods, handleSubmit, countdown } = useMeetingRedirectState();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%', width: 300, margin: 'auto' }}
      gap={2}
    >
      <Typography variant="h4" textAlign="center">
        Your meeting will start automatically.
      </Typography>

      <FormProvider methods={methods} onSubmit={handleSubmit}>
        <Stack
          direction={'column'}
          spacing={2}
          style={{ width: 300, paddingTop: 16 }}
        >
          {/* <RHFCheckbox
            name="isBotEnabled"
            label="Record using bots"
            checked={recordingOption === 'bot'}
            onChange={(e) => {
              if (e.target.checked) {
                setRecordingOption('bot');
              } else {
                setRecordingOption('browser');
              }
            }}
          />

          <RHFTextField
            name="joinUrl"
            label="Invite / Join Url"
            helperText={joinUrlHelperText}
          /> */}

          <LoadingButton
            variant="contained"
            size="large"
            type="submit"
            fullWidth
          >
            Start Meeting {countdown ? `(${countdown})` : ''}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Box>
  );
};

import { useEffect, useState, useCallback } from 'react';
import zoomSdk, {
  type GetMeetingContextResponse,
  type RunningContext,
} from '@zoom/appssdk';

interface Props {
  runningContext: RunningContext | null;
}

export const useZoomMeetingInfo = ({ runningContext }: Props) => {
  const [zoomMeetingInfo, setZoomMeetingInfo] =
    useState<GetMeetingContextResponse | null>(null);

  const getZoomMeetingInfo = useCallback(async () => {
    if (!runningContext) {
      return;
    }

    if (runningContext === 'inMeeting') {
      try {
        const meetingInfo = await zoomSdk.getMeetingContext();
        setZoomMeetingInfo(meetingInfo);
      } catch (err) {
        console.error(err);
      }
    }
  }, [runningContext]);

  useEffect(() => {
    void getZoomMeetingInfo();
  }, [getZoomMeetingInfo]);

  return { zoomMeetingInfo };
};

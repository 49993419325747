import { useState } from 'react';
import { Box, Button, Typography, Pagination, Skeleton } from '@mui/material';
import parse from 'html-react-parser';

import { ReactComponent as ChevronLeftSVG } from '@/assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightSVG } from '@/assets/icons/chevron-right.svg';
import type { Script } from '@/types';

interface ScriptTabProps {
  meetingScript: Script;
  isLoading: boolean;
}

const ScriptSkeleton = () => (
  <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
    {[...Array(10)].map((_, i) => (
      <Skeleton key={i} component="text" height={28} sx={{ mb: 1 }} />
    ))}
  </Box>
);

export const ScriptTab = ({ meetingScript, isLoading }: ScriptTabProps) => {
  const [page, setPage] = useState(1);

  const pageCount = meetingScript?.pages.length ?? 0;

  return (
    <Box height={400}>
      <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
        <Box
          flex={1}
          p={2}
          mb={2}
          sx={{ overflowY: 'auto', border: 'solid', borderRadius: 1 }}
        >
          {isLoading ? (
            <ScriptSkeleton />
          ) : (
            <Typography lineHeight={1.75}>
              {meetingScript?.pages.length
                ? parse(meetingScript.pages[page - 1])
                : 'No script available for this meeting type'}
            </Typography>
          )}
        </Box>
        <Box display="flex">
          <Button
            variant="contained"
            disabled={page === 1 || isLoading}
            startIcon={<ChevronLeftSVG />}
            onClick={() => setPage((prev) => prev - 1)}
          >
            Prev
          </Button>
          <Box flex={1} display="flex" justifyContent="center">
            <Pagination
              page={page}
              color="primary"
              shape="rounded"
              size="small"
              count={pageCount}
              onChange={(_, value) => setPage(value)}
              boundaryCount={0}
              siblingCount={0}
              hidePrevButton
              hideNextButton
            />
          </Box>
          <Button
            variant="contained"
            disabled={page === pageCount || isLoading}
            endIcon={<ChevronRightSVG />}
            onClick={() => setPage((prev) => prev + 1)}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

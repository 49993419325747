import { Snackbar as MUISnackbar, Alert } from '@mui/material';

import { useSnackbarStore } from '@/stores/snackbar';

export const Snackbar = () => {
  const { open, type, message, dismissSnackbar } = useSnackbarStore();

  return (
    <MUISnackbar
      open={open}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={dismissSnackbar}
      sx={{ mb: 5 }}
    >
      <Alert severity={type} onClose={dismissSnackbar} sx={{ width: '80%' }}>
        {message}
      </Alert>
    </MUISnackbar>
  );
};

import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import { formatErrorMessage } from '@/utils/format';
import { useSnackbarStore } from '@/stores/snackbar';
import { type MutationConfig } from '@/lib/reactQuery';

interface Params {
  teamId: string;
  meetingId: string;
  meetingNoteId: string;
  note: string;
}

export const updateMeetingNote = async ({
  teamId,
  meetingId,
  meetingNoteId,
  note,
}: Params): Promise<void> => {
  const result = await fetch(
    `${Server.middlewareUrl}/v1/teams/${teamId}/meetings/${meetingId}/notes/${meetingNoteId}`,
    {
      method: 'POST',
      body: JSON.stringify({
        value: note,
      }),
      headers: {
        'x-request-id': uuidv4(),
        'Content-Type': 'application/json',
        'X-Quil-Token': `${localStorage.getItem('X-Quil-Token') ?? ''}`,
      },
    }
  );
  await _handleErrors(result);
};

interface MutationOptions {
  config?: MutationConfig<typeof updateMeetingNote>;
}

export const useUpdateMeetingNote = ({ config }: MutationOptions) => {
  const { showSnackbar } = useSnackbarStore();

  return useMutation({
    mutationFn: updateMeetingNote,
    onError: async (error: unknown) => {
      showSnackbar(formatErrorMessage(error), 'error');
    },
    ...config,
  });
};

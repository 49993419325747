import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import {
  Box,
  Button,
  CircularProgress as Spinner,
  Typography,
} from '@mui/material';

import ThemeProvider from '@/lib/mui';
import { queryClient } from '@/lib/reactQuery';

import { Snackbar } from '@/components/snackbar/Snackbar';
import { AccountProvider } from './AccountProvider';

const ErrorFallback = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box>
        <Typography variant="h4">Ooops, something went wrong :( </Typography>
        <Button
          sx={{ mt: 2 }}
          onClick={() => window.location.assign(window.location.origin)}
        >
          Refresh
        </Button>
      </Box>
    </Box>
  );
};

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ThemeProvider>
      <React.Suspense
        fallback={
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner />
          </Box>
        }
      >
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={(error) => {
            Sentry.captureException(error);
          }}
        >
          <QueryClientProvider client={queryClient}>
            <Snackbar />
            <AccountProvider>
              <Router>{children}</Router>
            </AccountProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </React.Suspense>
    </ThemeProvider>
  );
};

import { useAppPreferencesStore } from '@/stores/appPreferences';
import { useMeetingStore } from '@/stores/meeting';
import { useSnackbarStore } from '@/stores/snackbar';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { openExternalUrl } from '@/features/auth/api/openExternalUrl';
import { App } from '@/config';
import { getMeetingJoinUrl } from '@/features/meeting/api/getMeetingJoinUrl';

export const useMeetingRedirectState = () => {
  const [countdown, setCountdown] = useState<number | null>(15);
  const { teamId, meetingId, selectedMeetingType } = useMeetingStore();
  const { recordingOption, setRecordingOption } = useAppPreferencesStore();
  const meetingTypeId = selectedMeetingType?.$id ?? '';
  const defaultValues = {
    joinUrl: '' as string,
    isBotEnabled: recordingOption === 'bot',
  };

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        isBotEnabled: Yup.boolean().required(),
        joinUrl: Yup.string().when('isBotEnabled', {
          is: (isBotEnabled: any) => {
            return isBotEnabled;
          },
          then: () => Yup.string().required('Required'),
        }),
      })
    ),
    defaultValues,
  });

  const { showSnackbar } = useSnackbarStore();
  const navigate = useNavigate();

  useEffect(() => {
    getMeetingJoinUrl()
      .then((joinUrl) => {
        methods.setValue('joinUrl', joinUrl);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [methods]);

  const launchCompanion = useCallback(
    async (teamId: string, meetingId: string, joinUrl: string | null) => {
      let resource = `/app/team/${teamId}/meetings/${meetingId}/companion`;
      if (joinUrl && recordingOption === 'bot') {
        resource += `?joinUrl=${encodeURIComponent(joinUrl)}`;
      }

      const url = new URL('/desktop-routing', App.mainUrl);
      url.searchParams.append('resource', encodeURIComponent(resource));

      await openExternalUrl({
        url: url.toString(),
      });
      navigate('/app/meeting/ongoing');
    },
    [navigate, recordingOption]
  );

  const handleSubmit = methods.handleSubmit(async ({ joinUrl }) => {
    await launchCompanion(teamId, meetingId, joinUrl);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((countdown) => {
        if (countdown === null) {
          return null;
        }

        if (countdown === 0) {
          void handleSubmit();
          return null;
        } else {
          countdown--;
        }
        return countdown;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [setCountdown, handleSubmit]);

  const methodsJoinUrl = methods.getValues().joinUrl;
  const joinUrlHelperText = useMemo(() => {
    if (methodsJoinUrl) {
      // An error has occured
      return 'Meeting url from Zoom, Google Meets or Teams.';
    } else {
      return 'Your bot will join this meeting.';
    }
  }, [methodsJoinUrl]);

  return {
    teamId,
    meetingId,
    meetingTypeId,
    showSnackbar,
    navigate,
    methods,
    handleSubmit,
    setRecordingOption,
    countdown,
    joinUrl: methods.getValues().joinUrl,
    joinUrlHelperText,
    recordingOption,
  };
};

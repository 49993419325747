import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import { type QueryConfig } from '@/lib/reactQuery';
import type { Contact, Response } from '@/types';

export const getIntegrationDeals = async (
  teamId: string,
  limit: number,
  hint?: string
): Promise<Response<Contact[]>> => {
  const request = {
    method: 'GET',
    headers: {
      'x-request-id': uuidv4(),
      'Content-Type': 'application/json',
      'X-Quil-Token': `${localStorage.getItem('X-Quil-Token') ?? ''}`,
    },
  };

  const url = new URL(
    `/v1/teams/${teamId}/integrations/deals`,
    Server.middlewareUrl
  );
  url.searchParams.append('limit', `${limit}`);
  hint && url.searchParams.append('hint', hint);

  const response = await fetch(url.toString(), request);

  await _handleErrors(response);
  return await response.json();
};

type QueryFnType = typeof getIntegrationDeals;

interface UseIntegrationDealsOptions {
  teamId: string;
  limit: number;
  hint?: string;
  config?: QueryConfig<QueryFnType>;
}

export const useIntegrationDeals = ({
  teamId,
  limit,
  hint,
  config,
}: UseIntegrationDealsOptions) => {
  return useQuery<Contact[], unknown, Contact[], any>({
    ...config,
    queryKey: ['deals', teamId, limit, hint],
    queryFn: () => getIntegrationDeals(teamId, limit, hint),
    enabled: !!teamId,
    select: (response: Response<Contact[]>) => response.data,
  });
};

import { v4 as uuidv4 } from 'uuid';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import type { TeamMember, Response } from '@/types';

interface GetAccountMembershipParams {
  teamId: string;
  accountId: string;
}

export const getAccountMembership = async ({
  teamId,
  accountId,
}: GetAccountMembershipParams): Promise<Response<TeamMember>> => {
  const request = {
    method: 'GET',
    headers: {
      'x-request-id': uuidv4(),
      'X-Quil-Token': `${localStorage.getItem('X-Quil-Token') ?? ''}`,
    },
  };

  const url = `${Server.middlewareUrl}/v1/teams/${teamId}/members/${accountId}`;
  const response = await fetch(url, request);
  await _handleErrors(response);
  return await response.json();
};

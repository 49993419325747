import { useFormContext, Controller } from 'react-hook-form';
import { TextField, type StandardTextFieldProps } from '@mui/material';

interface Props extends StandardTextFieldProps {
  name: string;
  helperText?: string;
}

export const RHFTextField = ({
  name,
  label,
  helperText,
  onChange,
  ...other
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            label={label}
            value={
              typeof field.value === 'number' && field.value === 0
                ? ''
                : field.value
            }
            error={!!error}
            helperText={error?.message ?? helperText}
            fullWidth
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e);
            }}
            {...other}
          />
        );
      }}
    />
  );
};

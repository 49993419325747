import palette from './palette';
import typography from './typography';
import shadows, { customShadows } from './shadows';

const themeOptions = {
  palette,
  shape: { borderRadius: 8 },
  typography,
  shadows,
  customShadows,
};

export default themeOptions;

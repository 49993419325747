import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import { useSnackbarStore } from '@/stores/snackbar';
import { formatErrorMessage } from '@/utils/format';
import { type MutationConfig } from '@/lib/reactQuery';

interface LinkIntegrationDealParams {
  teamId: string;
  meetingId: string;
  dealId?: string | null;
}

export const linkIntegrationDeal = async ({
  teamId,
  meetingId,
  dealId,
}: LinkIntegrationDealParams) => {
  const request = {
    method: 'POST',
    body: JSON.stringify({
      dealId,
    }),
    headers: {
      'x-request-id': uuidv4(),
      'Content-Type': 'application/json',
      'X-Quil-Token': `${localStorage.getItem('X-Quil-Token') ?? ''}`,
    },
  };

  const response = await fetch(
    `${Server.middlewareUrl}/v1/teams/${teamId}/meetings/${meetingId}/functions/link-deal`,
    request
  );
  await _handleErrors(response);
  return await response.json();
};

interface UseLinkIntegrationDealOptions {
  config?: MutationConfig<typeof linkIntegrationDeal>;
}

export const useLinkIntegrationDeal = ({
  config,
}: UseLinkIntegrationDealOptions) => {
  const { showSnackbar } = useSnackbarStore();
  return useMutation({
    onError: async (error) => {
      showSnackbar(formatErrorMessage(error), 'error');
      console.error(error);
    },
    ...config,
    mutationFn: linkIntegrationDeal,
  });
};

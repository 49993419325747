import { useCallback, useState, type SyntheticEvent, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

import { useMeetingStore } from '@/stores/meeting';
import { useNotesStore } from '@/stores/notes';
import { NotesTab } from '@/features/notes/components/NotesTab';
import { ScriptTab } from '@/features/scripts/components/ScriptTab';
import { ScorecardTab } from '@/features/scorecards/components/ScorecardTab';

import { TabPanel, a11yProps } from './TabPanel';
import { useMeetingDetails } from '../api/getMeetingDetails';

enum TabPanelType {
  Scorecard = 0,
  Script = 1,
  Notes = 2,
}

const TabControl = () => {
  const { teamId, meetingId, isInternalMeeting } = useMeetingStore();
  const { meetingNotes, setMeetingNotes } = useNotesStore();
  const { data: meetingDetails, isLoading } = useMeetingDetails({
    teamId,
    meetingId,
  });

  const meetingScorecard = meetingDetails?.meetingScorecard;
  const meetingScript = meetingDetails?.meetingScript;

  const getDefaultTab = useCallback(() => {
    if (isInternalMeeting) {
      return TabPanelType.Notes;
    }

    if (meetingScorecard) {
      return TabPanelType.Scorecard;
    }

    if (meetingScript) {
      return TabPanelType.Script;
    }

    return TabPanelType.Notes;
  }, [isInternalMeeting, meetingScorecard, meetingScript]);

  const defaultTab = getDefaultTab();
  const [tabValue, setTabValue] = useState(defaultTab);

  const handleTabChange = useCallback(
    (_: SyntheticEvent, newValue: number): void => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  // Transform the meeting notes to the format expected by the NotesTab component
  useEffect(() => {
    if (!meetingDetails) {
      return;
    }

    const meetingNotes = meetingDetails?.meetingNotes ?? [];
    const transformedMeetingNotes = meetingNotes
      .filter((item) => item.category === null || item.category === 'General') // Only allow general right now
      .map((note) => {
        return {
          ...note,
          category: note.category ?? 'General',
        };
      });
    // Storing notes in the store as we will be constantly updating them
    // React query cache is not the best place for that
    setMeetingNotes(transformedMeetingNotes);
  }, [meetingDetails, setMeetingNotes]);

  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        aria-label="meeting-tabs"
      >
        {!isInternalMeeting && meetingScorecard && (
          <Tab
            label="Scorecard"
            sx={{ flex: 1 }}
            {...a11yProps(TabPanelType.Scorecard)}
          />
        )}
        {!isInternalMeeting && meetingScript && (
          <Tab
            label="Script"
            sx={{ flex: 1 }}
            {...a11yProps(TabPanelType.Script)}
          />
        )}
        <Tab
          label="Notes"
          sx={{ flex: 1 }}
          {...a11yProps(TabPanelType.Notes)}
        />
      </Tabs>

      {!isInternalMeeting && meetingScorecard && (
        <TabPanel value={tabValue} index={TabPanelType.Scorecard}>
          <ScorecardTab meetingScorecard={meetingScorecard} />
        </TabPanel>
      )}

      {!isInternalMeeting && meetingScript && (
        <TabPanel value={tabValue} index={TabPanelType.Script}>
          <ScriptTab meetingScript={meetingScript} isLoading={isLoading} />
        </TabPanel>
      )}

      <TabPanel value={tabValue} index={TabPanelType.Notes}>
        <NotesTab meetingNotes={meetingNotes} />
      </TabPanel>
    </Box>
  );
};

export default TabControl;

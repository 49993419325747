import { Box, Typography } from '@mui/material';

import { useAccountContext } from '@/providers';

import { TeamList, TeamListSkeleton } from '../components/TeamList';
import { useTeamsAggregate } from '../api/getTeamsAggregate';
import { type TeamAggregate } from '@/types';

export const TeamsDashboard = () => {
  const { account } = useAccountContext();
  const accountId = account?.$id ?? '';
  const { data, isLoading: isTeamsLoading } = useTeamsAggregate({
    accountId,
  });
  const teams = Object.values(data ?? {});

  const renderContent = () => {
    if (isTeamsLoading) {
      return <TeamListSkeleton />;
    }

    if (!teams?.length) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={1}
          sx={{ height: '100%' }}
        >
          <Typography variant="h4">No teams found</Typography>
          <Typography textAlign="center">
            Please contact support@salesq.io to get started with Quil!
          </Typography>
        </Box>
      );
    }

    if (teams) {
      return teams.map((team: TeamAggregate) => (
        <TeamList key={team.$id} team={team} />
      ));
    }
  };

  return <>{renderContent()}</>;
};

import { Box, Typography, useTheme } from '@mui/material';

import TabControl from '../components/TabControl';
import { useMeetingState } from '../stateholder/MeetingState';
import { SearchDeals } from '../components/SearchDeals';

export const Meeting = () => {
  const theme = useTheme();
  const { teamId, meetingId, selectedMeetingType } = useMeetingState();

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={3}>
        <Box flex={1}>
          {selectedMeetingType && (
            <Typography
              component="h2"
              variant="subtitle1"
              color={theme.palette.text.secondary}
            >
              {selectedMeetingType.name}
            </Typography>
          )}
        </Box>
      </Box>
      <SearchDeals teamId={teamId} meetingId={meetingId} />
      <Box mb={2} />
      <TabControl />
    </Box>
  );
};

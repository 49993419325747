import { useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMeetingStore } from '@/stores/meeting';
import { useAppPreferencesStore } from '@/stores/appPreferences';
import { ReactComponent as CloseSVG } from '@/assets/icons/close.svg';
import { ReactComponent as ArrowBackSVG } from '@/assets/icons/arrow-back-rounded.svg';
import { ReactComponent as DotsHorizontalSVG } from '@/assets/icons/dots-horizontal.svg';
import { useDeleteCurrentSession } from '@/features/auth/api/logout';

export const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { reset: resetMeetingStore } = useMeetingStore();
  const { reset: resetAppPreferencesStore } = useAppPreferencesStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDashboard = pathname === '/app';

  const deleteSessionMutation = useDeleteCurrentSession({});

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    deleteSessionMutation.mutate(undefined, {
      onSettled: () => {
        resetMeetingStore();
        resetAppPreferencesStore();
        localStorage.removeItem('X-Quil-Token');
        navigate('/', { replace: true });
      },
    });
  };

  const handleReset = () => {
    resetMeetingStore();
    resetAppPreferencesStore();
    handleClose();
    navigate('/app', { replace: true });
  };

  const hasExitNavigation =
    window.location.pathname === '/app/meeting/redirect';

  return (
    <AppBar position="static" color="transparent" sx={{ mb: 1, boxShadow: 0 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {hasExitNavigation ? (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            disabled={isDashboard}
            onClick={() => handleReset()}
          >
            <CloseSVG />
          </IconButton>
        ) : (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            disabled={isDashboard}
            onClick={() => navigate(-1)}
          >
            <ArrowBackSVG />
          </IconButton>
        )}

        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <DotsHorizontalSVG />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              void handleReset();
            }}
          >
            Reset
          </MenuItem>
          <MenuItem
            onClick={() => {
              void handleLogout();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

import { useNavigate, useRoutes } from 'react-router-dom';

import { useAccountContext } from '@/providers';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

// eslint-disable-next-line react-refresh/only-export-components
export let routerNavigate: (route: string) => void = () => {
  console.error('Router not available');
};

export const AppRoutes = () => {
  const { account } = useAccountContext();
  const navigate = useNavigate();

  routerNavigate = (route: string) => {
    if (window.location.pathname === route) {
      return;
    }
    navigate(route);
    window.location.reload();
  };

  const routes = account ? [...protectedRoutes, ...publicRoutes] : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};

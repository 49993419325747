import { create } from 'zustand';

import { type MeetingNote } from '@/features/notes/types';

interface State {
  meetingNotes: MeetingNote[];
  isNoteInProgress: boolean;
}

interface Action {
  setMeetingNotes: (meetingNotes: MeetingNote[]) => void;
  setIsNoteInProgress: (isNoteInProgress: boolean) => void;
}

export const useNotesStore = create<State & Action>((set) => ({
  meetingNotes: [],
  isNoteInProgress: false,

  setMeetingNotes: (meetingNotes) => set({ meetingNotes }),
  setIsNoteInProgress: (isNoteInProgress) => set({ isNoteInProgress }),
}));

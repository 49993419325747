interface Props {
  hexColor?: string;
}

export const Wave = ({ hexColor = '#2065D1' }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 260">
      <g transform="translate(0, -15)">
        <path
          fill={hexColor}
          fillOpacity="1"
          d="M0,224L48,208C96,192,192,160,288,154.7C384,149,480,171,576,186.7C672,203,768,213,864,208C960,203,1056,181,1152,149.3C1248,117,1344,75,1392,53.3L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </g>
    </svg>
  );
};

import { create } from 'zustand';

type SnackbarType = 'info' | 'success' | 'error';

interface State {
  open: boolean;
  message: string;
  type: SnackbarType;
}

interface Action {
  showSnackbar: (message: string, type: SnackbarType) => void;
  dismissSnackbar: () => void;
}

export const useSnackbarStore = create<State & Action>((set) => ({
  open: false,
  message: '',
  type: 'info',

  showSnackbar: (message, type) =>
    set(() => ({
      open: true,
      message,
      type,
    })),
  dismissSnackbar: () =>
    set(() => ({
      open: false,
      message: '',
    })),
}));

import { v4 as uuidv4 } from 'uuid';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import type { Team, Response } from '@/types';

export const getTeams = async (): Promise<Response<Team[]>> => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'x-request-id': uuidv4(),
      'Content-Type': 'application/json',
      'X-Quil-Token': `${localStorage.getItem('X-Quil-Token') ?? ''}`,
    },
  };

  const url = `${Server.middlewareUrl}/v1/teams`;
  const response = await fetch(url.toString(), requestOptions);

  await _handleErrors(response);
  return await response.json();
};

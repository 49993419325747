import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Container, Paper, Box, useTheme, styled } from '@mui/material';

import { useAccountContext } from '@/providers';

import { Navbar } from '../navigation/Navbar';
import { Logo } from '../misc/Logo';
import { ClientViewMessaging } from './ClientViewMessaging';
import { App } from '@/config';

interface Props {
  children: React.ReactNode;
}

const StyledContainer = styled(Container)(() => ({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  minHeight: 'inherit',
  width: '100%',
  minWidth: 350,
  maxWidth: 1200,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

export const MainLayout = ({ children }: Props) => {
  const { account, isLoading, runningContext, isZoomSdkLoading } =
    useAccountContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMeetingWindowOpen = runningContext === 'inMeeting';

  useEffect(() => {
    if (!account && !isLoading) {
      navigate('/', { replace: true });
    }
  }, [account, isLoading, navigate]);

  if (isLoading || isZoomSdkLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%' }}
      >
        <Logo height={100} width={100} />
      </Box>
    );
  }

  if (App.env === 'production' && !isMeetingWindowOpen && !isZoomSdkLoading) {
    return <ClientViewMessaging />;
  }

  return (
    <StyledContainer maxWidth={false} disableGutters>
      <StyledPaper elevation={3}>
        <Navbar />
        <Box
          width="100%"
          height="100%"
          sx={{ px: theme.spacing(0.5), pb: theme.spacing(2) }}
        >
          {children}
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Button, Stack, IconButton, InputAdornment } from '@mui/material';

import { App } from '@/config';
import { RHFTextField, FormProvider } from '@/components/form';
import { ReactComponent as EyeSVG } from '@/assets/icons/eye.svg';
import { ReactComponent as EyeOffSVG } from '@/assets/icons/eye-off.svg';

import { openExternalUrl } from '../api/openExternalUrl';

interface Props {
  handleLogin: (email: string, password: string) => Promise<void>;
}

export const LoginForm = ({ handleLogin }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Enter a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(async ({ email, password }) => {
        void handleLogin(email, password);
      })}
    >
      <Stack spacing={4} sx={{ mt: 2 }}>
        <RHFTextField name="email" label="Email address" data-cy="email" />
        <RHFTextField
          name="password"
          label="Password"
          data-testid="password"
          data-cy="password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <EyeSVG /> : <EyeOffSVG />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Link
          variant="subtitle2"
          underline="hover"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            void openExternalUrl({
              url: `${App.mainUrl}/forgot-password`,
            });
          }}
          sx={{ ml: 'auto' }}
        >
          Forgot password?
        </Link>
      </Stack>

      <Button
        size="large"
        type="submit"
        variant="contained"
        data-cy="login"
        sx={{ textTransform: 'capitalize' }}
        fullWidth
      >
        Login
      </Button>
    </FormProvider>
  );
};

import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography, Box } from '@mui/material';

export const Page404 = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box width="100%" maxWidth={600} textAlign="center">
        <Typography variant="h4" sx={{ color: 'text.secondary' }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
          mistyped the URL? Be sure to check your spelling.
        </Typography>
        <Button to="/" size="large" component={RouterLink}>
          Go Home
        </Button>
      </Box>
    </Box>
  );
};

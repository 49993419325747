import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  type SelectChangeEvent,
} from '@mui/material';

import { lazyImport } from '@/utils/lazyImport';
import { useNotesStore } from '@/stores/notes';
import { useMeetingStore } from '@/stores/meeting';

import { useUpdateMeetingNote } from '../api/updateMeetingNote';
import { type MeetingNote } from '../types';

const { NoteTextArea } = lazyImport(
  () => import('./NoteTextArea'),
  'NoteTextArea'
);

interface NotesTabProps {
  meetingNotes: MeetingNote[];
}

const NOTE_TEXT_AREA_HEIGHT = '350px';

export const NotesTab = ({ meetingNotes }: NotesTabProps) => {
  const [currentValue, setCurrentValue] = useState<MeetingNote | null>(null);

  const { teamId, meetingId } = useMeetingStore();
  const { setMeetingNotes } = useNotesStore();

  const { mutateAsync: updateMeetingNote } = useUpdateMeetingNote({});

  const handleChange = useCallback(
    (event: SelectChangeEvent<MeetingNote | null>): void => {
      setCurrentValue(event.target.value as MeetingNote | null);
    },
    [setCurrentValue]
  );

  const handleUpdateNote = useCallback(
    async (meetingNoteId: string, category: string | null, note: string) => {
      await updateMeetingNote({
        teamId,
        meetingId,
        meetingNoteId,
        note,
      });
      const updatedMeetingNotes = meetingNotes.map((note) => {
        if (note.category === (category ?? 'General')) {
          return {
            ...note,
            manual: note.manual,
          };
        }
        return note;
      });
      setMeetingNotes(updatedMeetingNotes);
    },
    [teamId, meetingId, meetingNotes, setMeetingNotes, updateMeetingNote]
  );

  useEffect(() => {
    if (!teamId || !meetingId) return;

    if (!currentValue) {
      const defaultValue = meetingNotes.find(
        (note) => note.category === 'General'
      );
      defaultValue && setCurrentValue(defaultValue);
    } else {
      const selectedCategoryName = currentValue?.category;
      const updatedValue = meetingNotes.find(
        (note) => note.category === selectedCategoryName
      );
      updatedValue && setCurrentValue(updatedValue);
    }
  }, [teamId, meetingId, currentValue, meetingNotes, setMeetingNotes]);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="meeting-note-category-label">Category</InputLabel>
        <Select
          labelId="meeting-note-category-label"
          id="meeting-note-category"
          label="Category"
          value={currentValue ?? ''}
          disabled={true}
          onChange={handleChange}
        >
          {meetingNotes.map((note, index) => (
            // @ts-expect-error - necessary to load object into value
            <MenuItem key={`${note.category}-${index}`} value={note}>
              {note.category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box my={2}>
        <NoteTextArea
          value={currentValue?.manual?.note ?? ''}
          writerHeight={NOTE_TEXT_AREA_HEIGHT}
          onChange={(noteValue: string) => {
            if (currentValue) {
              void handleUpdateNote(
                currentValue.manual.$id,
                currentValue.manual.category,
                noteValue
              );
            }
          }}
        />
      </Box>
    </>
  );
};

export const _handleErrors = async (response: Response) => {
  if (response.status > 299 || response.status < 200) {
    let code = null;
    try {
      const result = await response.json();
      code = result.code || 'internal_server_error';
    } catch (e) {
      throw Error('internal_server_error');
    }
    throw Error(code);
  }
};

import { v4 as uuidv4 } from 'uuid';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';

import { useMutation } from '@tanstack/react-query';
import { useSnackbarStore } from '@/stores/snackbar';
import { type MutationConfig } from '@/lib/reactQuery';
import { updateMeetingHeartbeat } from './updateMeetingHeartbeat';

interface CreateMeetingParams {
  teamId: string;
  description: string;
  meetingTypeId: string | null;
}

interface CreateMeetingResponse {
  data: {
    meeting: {
      $id: string;
      userId: string;
      description: string;
      meetingTypeId: string;
      status: string;
      $createdAt: string;
      $updatedAt: string;
      $databaseId: string;
    };
  };
}

export const createMeeting = async ({
  teamId,
  description,
  meetingTypeId,
}: CreateMeetingParams): Promise<CreateMeetingResponse> => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      description,
      meetingTypeId,
    }),
    headers: {
      'x-request-id': uuidv4(),

      'X-Quil-Token': localStorage.getItem('X-Quil-Token') ?? '',
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(
    `${Server.middlewareUrl}/v1/teams/${teamId}/meetings`,
    requestOptions
  );

  await _handleErrors(response);
  const result = await response.json();

  await updateMeetingHeartbeat({
    teamId,
    meetingId: result.data.meeting.$id,
  });

  return result;
};

interface UseCreateMeetingOptions {
  config?: MutationConfig<typeof createMeeting>;
}

export const useCreateMeeting = ({ config }: UseCreateMeetingOptions) => {
  const { showSnackbar } = useSnackbarStore();

  return useMutation({
    onError: (error) => {
      showSnackbar(error.message, 'error');
    },
    ...config,
    mutationFn: ({ teamId, meetingTypeId, description }) =>
      createMeeting({ teamId, description, meetingTypeId }),
  });
};

import { Typography, Box } from '@mui/material';

import { type Scorecard } from '@/types';

interface ScorecardTabProps {
  meetingScorecard: Scorecard;
}

const boxStyle = {
  border: '2px gray solid',
  mb: 1.5,
  p: 1,
  borderRadius: 1,
};

export const ScorecardTab = ({ meetingScorecard }: ScorecardTabProps) => {
  return (
    <Box sx={{ mb: 2 }}>
      {meetingScorecard.events.map((event) => (
        <Box key={event.$id} sx={boxStyle}>
          <Typography variant="h6">{event.name}</Typography>
          <Typography>{event.description}</Typography>
        </Box>
      ))}
    </Box>
  );
};

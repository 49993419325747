import { v4 as uuidv4 } from 'uuid';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import { type MeetingType } from '@/types';

export interface MeetingTypeResponse {
  data: {
    meetingTypes: MeetingType[];
  };
}

export const getMeetingTypes = async (
  teamId: string
): Promise<MeetingTypeResponse> => {
  const request = {
    method: 'GET',
    headers: {
      'x-request-id': uuidv4(),
      'X-Quil-Token': `${localStorage.getItem('X-Quil-Token') ?? ''}`,
      'Content-Type': 'application/json',
    },
  };

  const result = await fetch(
    `${Server.middlewareUrl}/v1/teams/${teamId}/meeting-types`,
    request
  );

  await _handleErrors(result);
  return await result.json();
};

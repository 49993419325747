import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import { type QueryConfig } from '@/lib/reactQuery';
import type { Contact, Response } from '@/types';

interface Props {
  teamId: string;
  dealId: string;
}

export const getDealById = async ({
  teamId,
  dealId,
}: Props): Promise<Contact> => {
  const request = {
    method: 'GET',
    headers: {
      'x-request-id': uuidv4(),
      'Content-Type': 'application/json',
      'X-Quil-Token': `${localStorage.getItem('X-Quil-Token') ?? ''}`,
    },
  };

  const response = await fetch(
    `${Server.middlewareUrl}/v1/teams/${teamId}/integrations/deals/${dealId}`,
    request
  );

  await _handleErrors(response);
  return await response.json();
};

interface Options {
  teamId: string;
  dealId?: string | null;
  config?: QueryConfig<typeof getDealById>;
}

export const useGetDealById = ({ teamId, dealId, config }: Options) => {
  return useQuery<Contact, unknown, Contact, any>({
    ...config,
    queryKey: ['deal', teamId, dealId],
    queryFn: () => dealId && getDealById({ teamId, dealId }),
    enabled: !!teamId && !!dealId,
    select: (response: Response<Contact>) => response.data,
  });
};

import React, { useContext } from 'react';
import {
  type GetMeetingContextResponse,
  type RunningContext,
} from '@zoom/appssdk';

import { useZoomSdk } from '@/hooks/useZoomSdk';
import { useZoomMeetingInfo } from '@/hooks/useZoomMeetingInfo';
import { type AccountDetails } from '@/features/auth/types';
import { useAccountDetails } from '@/features/auth/api/getAccountDetails';

interface AuthContext {
  account: AccountDetails | undefined;
  isLoading: boolean;
  runningContext: RunningContext | null;
  userContextStatus: string;
  isZoomSdkLoading: boolean;
  zoomMeetingInfo: GetMeetingContextResponse | null;
}

interface Props {
  children: React.ReactNode;
}

const AccountContext = React.createContext<AuthContext>({
  account: undefined,
  isLoading: false,
  runningContext: null,
  userContextStatus: '',
  isZoomSdkLoading: false,
  zoomMeetingInfo: null,
});

export const AccountProvider = ({ children }: Props) => {
  const {
    runningContext,
    userContextStatus,
    isLoading: isZoomSdkLoading,
  } = useZoomSdk();
  const { zoomMeetingInfo } = useZoomMeetingInfo({ runningContext });
  const { data: account, isLoading } = useAccountDetails({});

  return (
    <AccountContext.Provider
      value={{
        account,
        isLoading,
        runningContext,
        userContextStatus,
        isZoomSdkLoading,
        zoomMeetingInfo,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAccountContext = () => useContext(AccountContext);

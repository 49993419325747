import { v4 as uuidv4 } from 'uuid';
import { decodeJwt } from 'jose';
import { useQuery } from '@tanstack/react-query';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import { type QueryConfig } from '@/lib/reactQuery';
import { type Response } from '@/types';
import { type AccountDetails } from '../types';
import { routerNavigate } from '@/routes';

interface GetAccountDetailsParams {
  accountId?: string;
}

interface GetAccountDetails {
  account: AccountDetails;
}

export const getAccountDetails = async ({
  accountId,
}: GetAccountDetailsParams): Promise<Response<GetAccountDetails>> => {
  const quilToken = localStorage.getItem('X-Quil-Token') ?? '';

  let _accountId: string;
  if (accountId) {
    _accountId = accountId;
  } else if (quilToken) {
    const decoded = decodeJwt(quilToken);
    _accountId = decoded.accountId as string;
  } else {
    console.error('Unknown Account');
    sessionStorage.clear();
    localStorage.clear();
    routerNavigate('/');
    throw new Error('Unknown Account');
  }

  const request = {
    method: 'GET',
    headers: {
      'x-request-id': uuidv4(),
      'X-Quil-Token': quilToken,
    },
  };

  const url = `${Server.middlewareUrl}/v1/accounts/${_accountId}/details`;
  const response = await fetch(url, request);

  await _handleErrors(response);
  return await response.json();
};

type QueryFnType = typeof getAccountDetails;

interface UseQueryTeamsOptions {
  accountId?: string;
  config?: QueryConfig<QueryFnType>;
}

export const useAccountDetails = ({
  accountId,
  config,
}: UseQueryTeamsOptions) => {
  return useQuery<AccountDetails, unknown, AccountDetails, any>({
    ...config,
    queryKey: ['account-details', accountId],
    queryFn: () => getAccountDetails({ accountId }),
    select: (response: Response<GetAccountDetails>) => response.data.account,
  });
};

import type { MeetingType } from '@/types';

export const SCORECARD_COLORS = [
  '#D1E9FC',
  '#76B0F1',
  '#2065D1',
  '#103996',
  '#061B64',
];

export const INTERNAL_MEETING = 'Internal Meeting';

export const INTERNAL_MEETING_TYPE: MeetingType = {
  $id: '',
  name: INTERNAL_MEETING,
  activityType: null,
  default: false,
  scorecard: null,
  script: null,
  noteTemplate: null,
  documentTemplates: [],
  noteDestinationId: null,
  $createdAt: '',
  $updatedAt: '',
};

export const CRM_COLORS: Record<string, string> = {
  salesforce: '#1798c1',
  hubspot: '#ff7a59',
  salesloft: '#006ba6',
};

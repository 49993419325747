import { v4 as uuidv4 } from 'uuid';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';

export const loginWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  const request = {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
    headers: {
      'x-request-id': uuidv4(),
      'Content-Type': 'application/json',
    },
  };

  const url = `${Server.middlewareUrl}/v1/sessions/email-session`;
  const response = await fetch(url, request);
  await _handleErrors(response);

  const result = await response.json();
  localStorage.setItem('X-Quil-Token', result.data);
};

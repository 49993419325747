import { useEffect, useState, useCallback } from 'react';
import { type RunningContext } from '@zoom/appssdk';

import { configureSdk } from '@/lib/zoom';

export const useZoomSdk = () => {
  const [runningContext, setRunningContext] = useState<RunningContext | null>(
    null
  );
  const [userContextStatus, setUserContextStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = useState(0);

  const configureZoomSdk = useCallback(async () => {
    setIsLoading(true);

    // to account for the 2 hour timeout for config
    const configTimer = setTimeout(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 120 * 60 * 1000);

    try {
      const configResponse = await configureSdk();
      setRunningContext(configResponse.runningContext);
      setUserContextStatus(configResponse.auth.status);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }

    return () => {
      clearTimeout(configTimer);
    };
  }, []);

  useEffect(() => {
    void configureZoomSdk();
  }, [counter, configureZoomSdk]);

  return { runningContext, userContextStatus, isLoading };
};

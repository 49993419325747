import { useQuery } from '@tanstack/react-query';

import { getTeams } from './getTeams';
import { getMeetingTypes } from '@/features/scorecards/api/getMeetingTypes';
import { getAccountMembership } from './getAccountMembership';
import { type QueryConfig } from '@/lib/reactQuery';
import type { TeamAggregate, Response } from '@/types';

interface GetTeamsParams {
  accountId: string;
}

export const getTeamsAggregate = async ({
  accountId,
}: GetTeamsParams): Promise<Response<Record<string, TeamAggregate>>> => {
  const { data: teams } = await getTeams();

  const aggregateTeams: Record<string, TeamAggregate> = {};

  for (const team of teams) {
    const meetingTypes = await getMeetingTypes(team.$id).then(
      (response) => response.data.meetingTypes
    );

    const membership = await getAccountMembership({
      teamId: team.$id,
      accountId,
    }).then((response) => response.data);

    aggregateTeams[team.$id] = {
      ...team, // Base team Object
      meetingTypes,
      membership,
    };
  }

  return {
    data: aggregateTeams,
  };
};

type QueryFnType = typeof getTeamsAggregate;

interface UseQueryTeamsOptions {
  accountId: string | undefined;
  config?: QueryConfig<QueryFnType>;
}

export const useTeamsAggregate = ({
  accountId,
  config,
}: UseQueryTeamsOptions) => {
  return useQuery<
    Record<string, TeamAggregate>,
    unknown,
    Record<string, TeamAggregate>,
    any
  >({
    ...config,
    queryKey: ['teams', accountId],
    queryFn: () => accountId && getTeamsAggregate({ accountId }),
    enabled: !!accountId,
    select: (response: Response<Record<string, TeamAggregate>>) =>
      response.data,
  });
};

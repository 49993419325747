export const Server = {
  env: import.meta.env.VITE_ENV || 'production',
  middlewareUrl: import.meta.env.VITE_PUBLIC_API_URL || '',
  sentryDsn: import.meta.env.VITE_SENTRY_DSN || '',
};

export const App = {
  env: import.meta.env.VITE_ENV || 'production',
  mainUrl: import.meta.env.VITE_MAIN_APP_URL || '',
};

import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';

import { Server } from '@/config';
import { _handleErrors } from '@/utils/error';
import { useSnackbarStore } from '@/stores/snackbar';
import { formatErrorMessage } from '@/utils/format';
import { type MutationConfig } from 'src/lib/reactQuery';
import { routerNavigate } from '@/routes';

export const deleteCurrentSession = async () => {
  const request = {
    method: 'DELETE',
    headers: {
      'x-request-id': uuidv4(),
      'Content-Type': 'application/json',
      'X-Quil-Token': `${localStorage.getItem('X-Quil-Token') ?? ''}`,
    },
  };

  const response = await fetch(
    `${Server.middlewareUrl}/v1/sessions/current`,
    request
  );

  await _handleErrors(response);

  sessionStorage.clear();
  localStorage.clear();
  routerNavigate('/');
};

interface UseDeleteCurrentSession {
  config?: MutationConfig<typeof deleteCurrentSession>;
}

export const useDeleteCurrentSession = ({
  config,
}: UseDeleteCurrentSession) => {
  const { showSnackbar } = useSnackbarStore();

  return useMutation({
    ...config,
    onError: (error) => {
      showSnackbar(formatErrorMessage(error), 'error');
      console.error(error);
    },
    mutationFn: deleteCurrentSession,
  });
};

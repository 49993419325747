import { type Contact } from '@/types';

export const formatErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  } else {
    return 'Unable to complete your request at this time.';
  }
};

const getContactTypeName = (contact: Contact) => {
  switch (contact.type) {
    case 'contact':
      return 'Contact';
    case 'candidate':
      return 'Candidate';
    default:
      return null;
  }
};

export const formatFullContact = (contact: Contact, includeType: boolean) => {
  const email = contact.emails?.[0];
  const phoneNumber = contact.phoneNumbers?.[0];

  const emailPart = email ? `(${email})` : null;
  const phoneNumberPart = phoneNumber ? `(${phoneNumber})` : null;
  const identifier = emailPart ?? phoneNumberPart ?? '';

  const _typePart = includeType ? getContactTypeName(contact) : null;
  const typePart = _typePart ? `- ${_typePart}` : '';

  if (contact.name.includes('(')) {
    // If the contact name is in the older "Name (email)" format. Do not add another ID.
    return `${contact.name} ${typePart}`;
  } else {
    return `${contact.name} ${identifier} ${typePart}`;
  }
};

export const formatContactList = (
  contacts: Contact[]
): Array<{ label: string; value: string }> => {
  const candidateType = contacts.find((item) => item.type === 'candidate');
  const contactType = contacts.find((item) => item.type === 'contact');
  const includeType = candidateType && contactType;

  const _contacts = [];
  for (const contact of contacts) {
    const contactName = formatFullContact(contact, !!includeType);

    _contacts.push({
      label: contactName,
      value: contact.id,
    });
  }
  return _contacts;
};

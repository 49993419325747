import { useState, useEffect } from 'react';

import { useMeetingStore } from '@/stores/meeting';
import { useUpdateMeetingHeartbeat } from '@/features/meeting/api/updateMeetingHeartbeat';

export const useMeetingHeartbeat = () => {
  const [hasHeartbeat, setHasHeartbeat] = useState(false);
  const { teamId, meetingId } = useMeetingStore();
  const { mutateAsync: updateMeetingHeartbeat } = useUpdateMeetingHeartbeat({
    teamId,
    meetingId,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      void updateMeetingHeartbeat({
        teamId,
        meetingId,
      });
      !hasHeartbeat && setHasHeartbeat(true);
    }, 1000 * 2);

    return () => clearInterval(interval);
  }, [updateMeetingHeartbeat, teamId, meetingId, hasHeartbeat]);

  return {
    hasHeartbeat,
  };
};

import { Login } from '@/features/auth/routes/Login';
import { Page404 } from '@/features/auth/routes/Page404';

export const publicRoutes = [
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/404',
    element: <Page404 />,
  },
];

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type RecordingOption = 'bot' | 'browser' | null;

interface State {
  isRedirectMessageHidden: boolean;
  recordingOption: RecordingOption;
}

interface Action {
  setIsRedirectMessageHidden: (isRedirectMessageHidden: boolean) => void;
  setRecordingOption: (recordingOption: RecordingOption) => void;
  reset: () => void;
}

const initialState: State = {
  isRedirectMessageHidden: false,
  recordingOption: null,
};

export const useAppPreferencesStore = create<State & Action>()(
  persist(
    (set) => ({
      ...initialState,

      setIsRedirectMessageHidden: (isRedirectMessageHidden) => {
        set({ isRedirectMessageHidden });
      },
      setRecordingOption: (recordingOption) => {
        set({ recordingOption });
      },
      reset: () => {
        set(() => ({
          ...initialState,
        }));
      },
    }),
    {
      name: 'app-preferences-storage',
    }
  )
);
